/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Card, Heading, Link as RebassLink, Text } from 'rebass/styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Hero } from '../../components/hero'
import { HeroLink } from '../../components/hero-link'
import { ContentSection } from '../../sections/content'
import reliefBg from '../../images/relief-bg.png'

const VerinlegnoPage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/nabizime-hero-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO title="Verinlegno" description="Rodinná líheň inovací z Itálie" />

      <Hero
        image={heroBg}
        bgPosition="center 60%"
        title="Verinlegno"
        text="Rodinná líheň inovací z Itálie"
        leadText
      />

      <ContentSection
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url(${reliefBg})`,
          backgroundSize: '100%, 50%',
        }}
      >
        <Box sx={{ position: 'relative', mt: -6 }}>
          <Card
            sx={{
              gridTemplateColumns: ['1fr', 'auto 1fr'],
              gap: 4,
              alignItems: 'center',
            }}
          >
            <StaticImage
              src="../../images/verinlegno-logo.png"
              alt=""
              placeholder="none"
              quality={90}
              height={89}
              objectFit="contain"
              objectPosition="0"
            />

            <Text as="p" sx={{ fontSize: '1.125rem', fontWeight: 'bold' }}>
              Italskou firmu Verinlegno založili v roce 1975 členové tří rodin a
              tyto tři rody včetně zakladatelů ji vedou dodnes. Typický rodinný
              duch je zde patrný na každém rohu.
            </Text>
          </Card>
        </Box>
      </ContentSection>

      <ContentSection sx={{ pt: 0 }}>
        <Text as="p">
          Z malé společnosti je dnes díky ambicím, skvělým nápadům, šikovnosti,
          technické zručnosti a vynalézavosti prosperující firma dodávající své
          produkty do více než stopadesáti zemí světa.
        </Text>
      </ContentSection>

      <ContentSection dark>
        <Heading variant="contentSectionTitle" as="h2">
          Spojuje nás pracovitost a osobní přístup k zákazníkovi
        </Heading>

        <Text as="p">
          Příběh naší spolupráce se začal psát v roce 2012. Tehdy jsme po velmi
          bolestném rozchodu přišli o hlavního dodavatele barev pro
          dřevozpracující průmysl. Při hledání nového jsme objevili Verinlegno.
          A zjistili jsme, že máme společnou filozofii: i tito Italové si
          zakládají na pracovitosti, osobním přístupu k zákazníkovi, servisu a
          kvalitě.
        </Text>
      </ContentSection>

      <ContentSection>
        <Text as="p">
          Společnost Verinlegno si jako svou misi vybralo nabízet kvalitní,
          bezpečné a inovativní produkty, a to s plnou odpovědností k životnímu
          prostředí, ochraně zdraví uživatelů a rozvoji lidských zdrojů.
        </Text>
        <Text as="p">
          Právě poslání a filozofie je to, co nás spojuje a co činí naši
          spolupráci naplňující.
        </Text>

        <Box variant="serviceFooter">
          <RebassLink
            variant="linkButton"
            href="https://eshop.frypo.cz/"
            target="_blank"
          >
            Prohlédnout si produkty Verinlegno
          </RebassLink>

          <HeroLink dark to="/nabizime/arboritec">
            Další: Arboritec
          </HeroLink>
        </Box>
      </ContentSection>
    </Layout>
  )
}

export default VerinlegnoPage
